import React, { useMemo } from 'react';

import { Trans } from '@lingui/react/macro';

import { DateTimeFormatter, LocalDateTime } from '@js-joda/core';

import { useQuery } from '@tanstack/react-query';

import Encoding from 'encoding-japanese';

import { axiosInstance as axios } from '~/axios';

import { operationLogFetcher } from './DashboardFetchers';
import { IDashboardLoggingData } from './models';

const OperationLogPortlet = (props: { tenantId: string } & React.HTMLAttributes<HTMLDivElement>) => {
  const { tenantId, className } = props;
  const { data, isPending, isError } = useQuery({
    queryKey: ['dashboard-operation-log', tenantId],
    enabled: tenantId != null,
    queryFn: ({ signal }) => operationLogFetcher(axios, tenantId, signal),
  });

  const r1 = useMemo(
    () =>
      data != null && data.status < 400 && data.data != null
        ? data?.data?.filter((x: IDashboardLoggingData) => x.actionHandle === 'schedule-success')
        : null,
    [data]
  );
  const r2 = useMemo(
    () =>
      data != null && data.status < 400 && data.data != null
        ? data?.data?.filter((x: IDashboardLoggingData) => x.actionHandle === 'extract-success')
        : null,
    [data]
  );
  const r3 = useMemo(
    () =>
      data != null && data.status < 400 && data.data != null
        ? data?.data?.filter(
            (x: IDashboardLoggingData) =>
              x.functionHandle === 'maintenanceBuilding-import' && x.actionHandle === 'registration-success'
          )
        : null,
    [data]
  );
  const r4 = useMemo(
    () =>
      data != null && data.status < 400 && data.data != null
        ? data?.data?.filter(
            (x: IDashboardLoggingData) =>
              x.functionHandle === 'maintenanceContract-import' && x.actionHandle === 'registration-success'
          )
        : null,
    [data]
  );
  const r5 = useMemo(
    () =>
      data != null && data.status < 400 && data.data != null
        ? data?.data?.filter(
            (x: IDashboardLoggingData) =>
              x.functionHandle === 'maintenanceCustomer-import' && x.actionHandle === 'registration-success'
          )
        : null,
    [data]
  );

  const rr1: IDashboardLoggingData | undefined = r1 != null && r1.length > 0 ? r1[0] : undefined;
  const rr2: IDashboardLoggingData | undefined = r2 != null && r2.length > 0 ? r2[0] : undefined;
  const rr3: IDashboardLoggingData | undefined = r3 != null && r3.length > 0 ? r3[0] : undefined;
  const rr4: IDashboardLoggingData | undefined = r4 != null && r4.length > 0 ? r4[0] : undefined;
  const rr5: IDashboardLoggingData | undefined = r5 != null && r5.length > 0 ? r5[0] : undefined;

  return (
    <div className={className}>
      <div className="mx-2 mt-2 rounded-t-lg bg-[#4372C4] pl-4 font-anton text-[12pt] font-semibold text-white">
        <Trans>Operation Log</Trans>
      </div>
      {isPending ? null : isError ? (
        <div className="text-red-900"></div>
      ) : (
        <div className="items-left mx-2 flex flex-col justify-center rounded-b-lg bg-white p-2 font-body">
          <h2 className="ml-2 text-[12pt]">
            <Trans>最近行われた更新</Trans>
          </h2>
          <div className="justify-betweeb flex w-full min-w-fit flex-row items-center">
            <div className="m-1 w-36 min-w-36 rounded-sm bg-[#DEEBF7] p-1 text-center text-[8pt] text-black">
              <Trans>作業計画作成</Trans>
            </div>
            <div className="half-width-kana m-1 w-full text-[8pt] text-black">
              {Encoding.toZenkanaCase(rr1?.description ?? '')}
            </div>
            <div className="m-1 w-40 min-w-40 text-[8pt] text-black">
              {rr1?.operationAt == null
                ? ''
                : LocalDateTime.parse(rr1?.operationAt).format(DateTimeFormatter.ofPattern('yyyy年MM月dd日 HH:mm'))}
            </div>
          </div>
          <div className="flex w-full min-w-fit flex-row items-center justify-between">
            <div
              className="m-1 w-36 min-w-36 rounded-sm bg-[#E3F0D9] p-1 text-center text-[8pt] text-black"
              style={{ fontFeatureSettings: "'tnum' 1, 'hwid' 1, 'jp04' 1" }}>
              <Trans>計画作成用データ抽出</Trans>
            </div>
            <div
              className="m-1 w-full text-[8pt] text-black"
              style={{ fontFeatureSettings: "'tnum' 1, 'hwid' 1, 'jp04' 1" }}>
              {Encoding.toZenkanaCase(rr2?.description ?? '')}
            </div>
            <div className="m-1 w-40 min-w-40 text-[8pt] text-black">
              {rr2?.operationAt == null
                ? ''
                : LocalDateTime.parse(rr2?.operationAt).format(DateTimeFormatter.ofPattern('yyyy年MM月dd日 HH:mm'))}
            </div>
          </div>
          <div className="flex w-full min-w-fit flex-row items-center justify-start">
            <div className="m-1 w-36 min-w-36 rounded-sm bg-[#FFF2CC] p-1 text-center text-[8pt] text-black">
              契約データ
            </div>
            <div
              className="m-1 w-full text-[8pt] text-black"
              style={{ fontFeatureSettings: "'tnum' 1, 'hwid' 1, 'jp04' 1" }}>
              {Encoding.toZenkanaCase(rr3?.description ?? '')}
            </div>
            <div className="m-1 w-40 min-w-40 text-[8pt] text-black">
              {rr3?.operationAt == null
                ? ''
                : LocalDateTime.parse(rr3?.operationAt).format(DateTimeFormatter.ofPattern('yyyy年MM月dd日 HH:mm'))}
            </div>
          </div>
          <div className="flex w-full min-w-fit flex-row items-center justify-start">
            <div className="m-1 w-36 min-w-36 rounded-sm bg-[#FAE5D6] p-1 text-center text-[8pt] text-black">
              物件マスタ
            </div>
            <div
              className="m-1 w-full text-[8pt] text-black"
              style={{ fontFeatureSettings: "'tnum' 1, 'hwid' 1, 'jp04' 1" }}>
              {Encoding.toZenkanaCase(rr4?.description ?? '')}
            </div>
            <div className="m-1 w-40 min-w-40 text-[8pt] text-black">
              {rr4?.operationAt == null
                ? ''
                : LocalDateTime.parse(rr4?.operationAt).format(DateTimeFormatter.ofPattern('yyyy年MM月dd日 HH:mm'))}
            </div>
          </div>
          <div className="flex w-full min-w-fit flex-row items-center justify-start">
            <div className="m-1 w-36 min-w-36 rounded-sm bg-[#F8F0FC] p-1 text-center text-[8pt] text-black">
              <Trans>取引先マスタ</Trans>
            </div>
            <div
              className="m-1 w-full text-[8pt] text-black"
              style={{ fontFeatureSettings: "'tnum' 1, 'hwid' 1, 'jp04' 1" }}>
              {Encoding.toZenkanaCase(rr5?.description ?? '')}
            </div>
            <div className="m-1 w-40 min-w-40 text-[8pt] text-black">
              {rr5?.operationAt == null
                ? ''
                : LocalDateTime.parse(rr5?.operationAt).format(DateTimeFormatter.ofPattern('yyyy年MM月dd日 HH:mm'))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationLogPortlet;
