import { createContext, useContext } from 'react';

import { AxiosInstance } from 'axios';

import LoggerFactory from '~/utils/logging/LoggerFactory';

let isAxiosDirty = true;

const AxiosContext = createContext<AxiosInstance | null>(null);

export const useAxios = () => {
  const axios = useContext(AxiosContext);
  if (axios == null) {
    throw new Error('useAxios must be used within a AxiosProvider');
  }

  return axios;
};

type AxiosProviderProps = {
  axios: AxiosInstance;
  children: React.ReactNode;
  tokenProvider?: () => string | null;
};

const AxiosProvider = (props: AxiosProviderProps) => {
  const { axios, children, tokenProvider } = props;

  if (isAxiosDirty) {
    isAxiosDirty = false;
    axios.interceptors.request.use(config => {
      const token = tokenProvider != null ? tokenProvider() : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
    axios.interceptors.response.use(
      response => response,
      // eslint-disable-next-line @typescript-eslint/require-await
      async error => {
        const log = LoggerFactory.getLogger('axios');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (error.response?.status) {
          case 500:
            log.warn('HTTP 500');

            return false;
          case 404:
            log.warn('HTTP 404');

            return false;
          case 401:
            log.warn('HTTP 401');
            // publish('logout', {});

            return false;
          default:
            return true;
        }
      }
    );
  }

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};

export default AxiosProvider;
