import { useEffect } from 'react';

import { Metric, onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals';

declare global {
  interface Window {
    dataLayer: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { dataLayer } = window;

const queue = new Set();

const flushQueue = () => {
  if (queue.size > 0) {
    const instanceId = window.instanceId ?? '';
    const clientId = localStorage.getItem('client-id') ?? '';
    const fingerprint = localStorage.getItem('fingerprint') ?? '';
    const tenantId = window.tenantId ?? '';
    const userId = window.userId ?? '';

    // Replace with whatever serialization method you prefer.
    // Note: JSON.stringify will likely include more data than you need.
    const data = {
      type: 'web-vitals',
      url: window.location.href,
      instanceId,
      clientId,
      fingerprint,
      tenantId,
      userId,
      body: {
        vitals: [...queue],
      },
    };

    const blob = new Blob([JSON.stringify(data)], {
      type: 'application/reports+json',
    });

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    (navigator.sendBeacon && navigator.sendBeacon('/network/reports/analytics-endpoint', blob)) ||
      fetch('/network/reports/analytics-endpoint', { body: blob, method: 'POST', keepalive: true });

    queue.clear();
  }
};

// Report all available metrics whenever the page is backgrounded or unloaded.
addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    flushQueue();
  }
});

const sendToGTM = (metric: Metric) => {
  const { name, delta, id } = metric;

  // globalなdataLayer変数がなければ生成する
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  window.dataLayer.push({
    event: 'web-vitals',
    'eventData.category': 'Web Vitals',
    'eventData.action': name,
    // Googleアナリティクスの指標は整数である必要があるため、値は丸められる。
    // CLSの場合、精度を上げるために、最初に値に1000を掛ける。
    'eventData.value': Math.round(name === 'CLS' ? delta * 1000 : delta),
    // `id`の値は現在のページの読み込みに固有。
    // 送信時に同じページからの複数の値（CLSなど）、Googleアナリティクスは、
    // このIDでグループ化して合計を計算する（注：必要なのは `eventLabel`で、レポートのディメンションになる）。
    'eventData.label': `${id}@${window.location.hostname}`,
  });
  queue.add(metric);
};

const WebVitals = () => {
  useEffect(() => {
    onTTFB(sendToGTM);
    onFCP(sendToGTM);
    onCLS(sendToGTM);
    onFID(sendToGTM);
    onLCP(sendToGTM);
  }, []);

  return null;
};
export default WebVitals;
