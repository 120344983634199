import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { axiosInstance as axios } from '~/axios';

const context = new AudioContext();

const loadSound = async (url: string) => {
  const response = await axios.get<Blob>(url, { responseType: 'blob' });
  if (response.status >= 400) {
    throw new Error(`Failed to load sound: ${response.statusText}`);
  }

  return await context.decodeAudioData(await response.data.arrayBuffer());
};

export const playSound = (buffer?: AudioBuffer, volume: number = 1.0) => {
  if (buffer != null) {
    const source = context.createBufferSource(); // creates a sound source
    source.buffer = buffer; // tell the source which sound to play
    source.connect(context.destination); // connect the source to the context's destination (the speakers)
    const gainNode = context.createGain(); // Create a gain node
    source.connect(gainNode); // Connect the source to the gain node
    gainNode.connect(context.destination); // Connect the gain node to the destination
    gainNode.gain.value = volume; // Set the volume
    //gainNode.gain.setValueAtTime(volume, 0); // set the volume to the desired level immediately
    source.start(0); // play the source at the deisred time 0=now
  }
};

type SoundBuffers = {
  buttonClicked?: AudioBuffer;
  completed?: AudioBuffer;
  conditionBatchRegistration?: AudioBuffer;
  conditionYearmonthBatch?: AudioBuffer;
  extracted?: AudioBuffer;
  posterDataCreated?: AudioBuffer;
  posterBatchMailSend?: AudioBuffer;
  workplanCompleted?: AudioBuffer;
  workplanExcelOut?: AudioBuffer;
  workplanOutput?: AudioBuffer;
};

export const soundBuffers: SoundBuffers = {};

void loadSound('/sounds/button-clicked.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.buttonClicked = buffer;
});
void loadSound('/sounds/completed.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.completed = buffer;
});
void loadSound('/sounds/condition-batch-registration.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.conditionBatchRegistration = buffer;
});
void loadSound('/sounds/condition-yearmonth-batch.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.conditionYearmonthBatch = buffer;
});
void loadSound('/sounds/extracted.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.extracted = buffer;
});
void loadSound('/sounds/poster-data-created.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.posterDataCreated = buffer;
});
void loadSound('/sounds/poster-batch-mail-send.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.posterBatchMailSend = buffer;
});
void loadSound('/sounds/workplan-completed.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.workplanCompleted = buffer;
});
void loadSound('/sounds/workplan-excel-out.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.workplanExcelOut = buffer;
});
void loadSound('/sounds/workplan-output.mp3').then((buffer: AudioBuffer) => {
  soundBuffers.workplanOutput = buffer;
});

const countAtom = atom(1);
const derivedAtom = atom(
  get => get(countAtom),
  (get, set) => {
    set(countAtom, c => c + 1);
  }
);

const storage = window.localStorage;

// @ts-ignore
export const _bellStateAtom = atomWithStorage<string | null>('bellState', null, storage);

export const bellStateAtom = atom(
  get => get(_bellStateAtom),
  (get, set) => {
    const state = get(_bellStateAtom) as string | null;
    switch (state) {
      case 'LOW':
        void set(_bellStateAtom, 'MEDIUM');
        playSound(soundBuffers.buttonClicked, -0.4);
        break;
      case 'MEDIUM':
        void set(_bellStateAtom, 'HIGH');
        playSound(soundBuffers.buttonClicked, 0.0);
        break;
      case 'HIGH':
        void set(_bellStateAtom, 'OFF');
        break;
      default:
        void set(_bellStateAtom, 'LOW');
        playSound(soundBuffers.buttonClicked, -0.8);
    }
  }
);
