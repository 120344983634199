'use client';
import { useEffect, useState } from 'react';

import { Text } from '@mantine/core';
import { IconArrowUpLeft } from '@tabler/icons-react';

import { cn } from 'mxcn';

import ApplicationLog from '~/utils/ApplicationLog';

import { useAxios } from '~/components/common/providers';

import Dashboard from 'src/features/aspi/Dashboard';

import { watchdog } from '~/workers/watchdog/watchdog-client';

import styles from './Welcome.module.css';

const WelcomeView = () => {
  const axios = useAxios();

  useEffect(() => {
    window.document.body.style.fontSize = 'var(--size-4)';
  }, []);

  useEffect(() => {
    void ApplicationLog(axios, 'welcome', 'enter', '');

    if (window.userId != null && window.userId.length > 0) {
      watchdog('welcome', 'ホーム');
    }

    // ログイン直後用の落ち葉拾い
    const h = setInterval(() => {
      watchdog('welcome', 'ホーム');
    }, 10000);

    return () => {
      clearInterval(h);
      void ApplicationLog(axios, 'welcome', 'exit', '');
    };
  }, []);

  const [role, setRole] = useState('');

  useEffect(() => {
    setRole(
      window.roles?.includes('r_sysadmin')
        ? 'r_sysadmin'
        : window.roles?.includes('r_admin')
          ? 'r_admin'
          : window.roles?.includes('r_user')
            ? 'r_user'
            : 'r_user'
    );
  }, [window.roles]);

  return (
    <>
      <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
        <Text
          className={cn(
            styles.text,
            'flex flex-row items-end justify-start font-body text-[15pt] font-bold text-red-700'
          )}>
          <IconArrowUpLeft size={48} />
          <span>メニューをクリックしてください。</span>
        </Text>
        {true ? (
          <Dashboard tenantId={window.tenantId!} className="relative top-0 z-0 h-full w-full bg-[#EBF3FF]" />
        ) : (
          <img style={{ marginTop: '-0.75rem' }} src={`/images/home/${role}.png`} alt="top" />
        )}
      </div>
    </>
  );
};

export default WelcomeView;
