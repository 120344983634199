import { createTheme } from '@mantine/core';
import type { MantineTheme } from '@mantine/core';
import { themeToVars } from '@mantine/vanilla-extract';

import { PartialObjectDeep } from 'type-fest/source/partial-deep';

export const theme: PartialObjectDeep<MantineTheme, {}> = createTheme({
  fontSmoothing: true,
  fontFamily: 'BIZ UDGothic, sans-serif',
  fontFamilyMonospace: 'BIZ UDGothic, monospace',
  headings: { fontFamily: 'BIZ UDGothic, sans-serif' },
  primaryColor: 'indigo',
  primaryShade: 6,
  fontSizes: {
    xs: '9pt', //12, // 9pt
    sm: '10.5pt', //14, // 10.5pt
    md: '12pt', //16, // 12pt
    lg: '15pt', //20, // 15pt
    xl: '18pt', //24, // 18pt
  },
  lineHeights: {
    xs: '1.0', //1.2,
    sm: '1.2', //1.4,
    md: '1.4', //1.6,
    lg: '1.6', //1.8,
    xl: '1.8', //
  },
  breakpoints: {
    xs: '48em', //768 /* 48em */,
    sm: '64em', //1024 /* 64em */,
    md: '80em', //1280 /* 80em */,
    lg: '90em', //1440 /* 96em */,
    xl: '120em', //1920 /* 96em */,
  },
  spacing: {
    xs: '0.5rem', //8, // 0.5rem
    sm: '1rem', //16, // 1rem
    md: '1.5rem', //24, // 1.5rem
    lg: '2rem', //32, // 2rem
    xl: '3rem', //48, // 3rem
  },
  radius: {
    xs: '0.125rem', //2, // 0.125rem
    sm: '0.25rem', //4, // 0.25rem
    md: '0.5rem', //8, // 0.5rem
    lg: '1rem', //16, // 1rem
    xl: '2rem', //32, // 2rem
  },
  shadows: {
    xs: '0 1px 2px 0 rgba(0, 0, 0, 0.15)',
    sm: '0 1px 3px 0 rgba(0, 0, 0, 0.3)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.3)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.3)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.3)',
  },
});

// CSS variables object, can be access in *.css.ts files
export const vars = themeToVars(theme);
