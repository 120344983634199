import { AxiosInstance } from 'axios';

import { publish } from '~/utils/event';

import type { ApplicationLoggingMessage } from '~/workers/session/message';

const ApplicationLog = async (
  axios: AxiosInstance,
  functionHandle: string,
  actionHandle: string,
  message: string,
  targetYearmonth?: string,
  sectionCode?: string,
  targetId?: string
) => {
  let functionName: string = '';
  let actionName: string = '';
  let actionNotice: string = '';
  let foregroundColor: string = '';
  let backgroundColor: string = '';
  let statusResult: string = '';
  switch (functionHandle) {
    case 'posterTemplatePreview':
      functionName = 'チラシフォームレイアウト確認';
      switch (actionHandle) {
        case 'enter':
          actionName = 'チラシフォームレイアウト確認画面を開く';
          actionNotice = 'チラシフォームレイアウト確認画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'チラシフォームレイアウト確認画面を閉じる';
          actionNotice = 'チラシフォームレイアウト確認から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'preview':
          actionName = 'プレビュー';
          actionNotice = '［プレビュー］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'posterTemplateList':
      functionName = 'チラシフォーム一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = 'チラシフォーム一覧画面を開く';
          actionNotice = 'チラシフォーム一覧画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'チラシフォーム一覧画面を閉じる';
          actionNotice = 'チラシフォーム一覧から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'create':
          actionName = '新規作成';
          actionNotice = '［新規作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'edit':
          actionName = 'フォーム名';
          actionNotice = '［フォーム名］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'flyer-template-upload-success':
          actionName = 'チラシフォームアップロード';
          actionNotice = '［参照］ボタンを押下　または　ドラッグ&ドロップ';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'flyer-template-upload-failure':
          actionName = 'チラシフォームアップロード';
          actionNotice = '［参照］ボタンを押下　または　ドラッグ&ドロップ';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'flyer-template-delete-success':
          actionName = 'チラシフォーム削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'flyer-template-delete-failure':
          actionName = 'チラシフォーム削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'selected-text-append':
          actionName = '選択テキスト追加/更新';
          actionNotice = '［追加］ボタン　または　[更新］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'selected-text-remove':
          actionName = '選択テキスト削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'posterProjectList':
      functionName = 'お知らせ状況一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = 'お知らせ状況一覧画面を開く';
          actionNotice = 'お知らせ状況一覧画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'お知らせ状況一覧画面を閉じる';
          actionNotice = 'お知らせ状況一覧から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'search':
          actionName = '検索';
          actionNotice = '［検索］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'generate':
          actionName = 'データ作成';
          actionNotice = '［データ作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '投函担当者登録';
          actionNotice = '［投函担当者登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'post-registration-success':
          actionName = '投函担当者の一括登録';
          actionNotice = '［投函担当者登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'post-registration-failure':
          actionName = '投函担当者の一括登録';
          actionNotice = '［投函担当者登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'mail':
          actionName = 'メール送信処理';
          actionNotice = '［メール送信処理］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'fax':
          actionName = 'FAX用出力';
          actionNotice = '［FAX用出力］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'print':
          actionName = '印刷用出力';
          actionNotice = '［印刷用出力］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'status':
          actionName = '連絡状況';
          actionNotice = '［連絡状況］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'setting':
          actionName = '設定';
          actionNotice = '［設定］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'project':
          actionName = '工程表';
          actionNotice = '［工程表］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'flyer':
          actionName = 'チラシ';
          actionNotice = '［チラシ］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'card':
          actionName = '管理カード';
          actionNotice = '［管理カード］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'mailChange':
          actionName = 'メール宛先一括変更';
          actionNotice = '［宛先の変更を登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          break;
        case 'registered-success':
          actionName = 'お知らせ設定の登録';
          actionNotice = '［お知らせ設定の登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registered-failure':
          actionName = 'お知らせ設定の登録';
          actionNotice = '［お知らせ設定の登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'deleted-success':
          actionName = 'お知らせ設定の削除';
          actionNotice = '［お知らせ設定の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'deleted-failure':
          actionName = 'お知らせ設定の削除';
          actionNotice = '［お知らせ設定の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'building-persist-success':
          actionName = '物件マスタにお知らせ設定を保存する';
          actionNotice = '［物件マスタにお知らせ設定を保存する］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'building-persist-failure':
          actionName = '物件マスタにお知らせ設定を保存する';
          actionNotice = '［物件マスタにお知らせ設定を保存する］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'building-load':
          actionName = '物件マスタからお知らせ設定を読込む';
          actionNotice = '［物件マスタからお知らせ設定を読込む］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'flyer-preview':
          actionName = 'チラシフォームプレビュー';
          actionNotice = '［印刷プレビュー］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'flyer-download':
          actionName = 'チラシフォームダウンロード';
          actionNotice = '［docxダウンロード］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'flyer-template-upload-success':
          actionName = 'チラシフォームアップロード';
          actionNotice = '［参照］ボタンを押下　または　ドラッグ&ドロップ';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'flyer-template-upload-failure':
          actionName = 'チラシフォームアップロード';
          actionNotice = '［参照］ボタンを押下　または　ドラッグ&ドロップ';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceBuilding':
      functionName = '物件マスタ一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = '物件マスタ一覧画面を開く';
          actionNotice = '物件マスタ画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '物件マスタ一覧画面を閉じる';
          actionNotice = '物件マスタ一覧から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'loaded':
          actionName = 'データ読込';
          actionNotice = 'データ読込';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'clearCondition':
          actionName = '検索クリア';
          actionNotice = '［検索クリア］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'addNew':
          actionName = '新規作成';
          actionNotice = '［新規作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'import':
          actionName = 'インポート';
          actionNotice = '［インポート］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'export':
          actionName = 'エクスポート';
          actionNotice = '［エクスポート］ボタンを押下';
          foregroundColor = '#888';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceBuilding-import':
      functionName = '物件マスタインポート';
      switch (actionHandle) {
        case 'enter':
          actionName = '物件マスタインポート画面を開く';
          actionNotice = '物件マスタインポート画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '物件マスタインポート画面を閉じる';
          actionNotice = '物件マスタインポートから他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '#bdd7ee';
          break;
        case 'registration-uploading':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '#bdd7ee';
          statusResult = '';
          break;
        case 'registration-success':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '#bdd7ee';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '#bdd7ee';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceBuilding-new':
      functionHandle = 'maintenanceBuilding-edit';
      functionName = '物件マスタ詳細（新規）';
      switch (actionHandle) {
        case 'enter':
          actionName = '物件マスタ詳細画面を開く';
          actionNotice = '物件マスタ詳細画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '物件マスタ詳細画面を閉じる';
          actionNotice = '物件マスタ詳細から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'map':
          actionName = '位置情報確認・編集';
          actionNotice = '［位置情報確認・編集］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceBuilding-edit':
      functionHandle = 'maintenanceBuilding-edit';
      functionName = '物件マスタ詳細（編集）';
      switch (actionHandle) {
        case 'enter':
          actionName = '物件マスタ詳細画面を開く';
          actionNotice = '物件マスタ詳細画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '物件マスタ詳細画面を閉じる';
          actionNotice = '物件マスタ詳細から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'loaded':
          actionName = 'データ読込';
          actionNotice = 'データ読込';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'complement':
          actionName = '指定条件の設定';
          actionNotice = '［指定条件の設定］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'map':
          actionName = '位置情報確認・編集';
          actionNotice = '［位置情報確認・編集］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceBuilding-map':
      functionName = '位置情報データ編集';
      switch (actionHandle) {
        case 'enter':
          actionName = '位置情報データ編集画面を開く';
          actionNotice = '位置情報データ編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '位置情報データ編集画面を閉じる';
          actionNotice = '位置情報データ編集から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceBuilding-complement':
      functionName = '物件指定条件の編集';
      switch (actionHandle) {
        case 'enter':
          actionName = '物件指定条件の編集画面を開く';
          actionNotice = '物件指定条件の編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '物件指定条件の編集画面を閉じる';
          actionNotice = '物件指定条件の編集から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '指定条件の削除';
          actionNotice = '［指定条件の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '指定条件の削除';
          actionNotice = '［指定条件の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '指定条件の削除';
          actionNotice = '［指定条件の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceCustomer':
      functionName = '取引先マスタ管理';
      switch (actionHandle) {
        case 'enter':
          actionName = '取引先マスタ管理画面を開く';
          actionNotice = '取引先マスタ管理画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '取引先マスタ管理画面を閉じる';
          actionNotice = '取引先マスタ管理から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'clearCondition':
          actionName = '検索クリア';
          actionNotice = '［検索クリア］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'addNew':
          actionName = '新規作成';
          actionNotice = '［新規作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'import':
          actionName = 'インポート';
          actionNotice = '［インポート］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'export':
          actionName = 'エクスポート';
          actionNotice = '［エクスポート］ボタンを押下';
          foregroundColor = '#888';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceCustomer-import':
      functionName = '取引先マスタインポート';
      switch (actionHandle) {
        case 'enter':
          actionName = '取引先マスタインポート画面を開く';
          actionNotice = '取引先マスタインポート画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '取引先マスタインポート画面を閉じる';
          actionNotice = '取引先マスタインポートから他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          break;
        case 'registration-uploading':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          statusResult = '';
          break;
        case 'registration-success':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceCustomer-new':
      functionHandle = 'maintenanceCustomer-edit';
      functionName = '取引先マスタ詳細（新規）';
      switch (actionHandle) {
        case 'enter':
          actionName = '取引先マスタ詳細画面を開く';
          actionNotice = '取引先マスタ詳細画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '取引先マスタ詳細画面を閉じる';
          actionNotice = '取引先マスタ詳細から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'warning';
          break;
      }
      break;
    case 'maintenanceCustomer-edit':
      functionHandle = 'maintenanceCustomer-edit';
      functionName = '取引先マスタ詳細（編集）';
      switch (actionHandle) {
        case 'enter':
          actionName = '取引先マスタ詳細画面を開く';
          actionNotice = '取引先マスタ詳細画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '取引先マスタ詳細画面を閉じる';
          actionNotice = '取引先マスタ詳細から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'yearlyContract':
      functionName = '物件別契約情報管理';
      switch (actionHandle) {
        case 'enter':
          actionName = '物件別契約情報管理を開く';
          actionNotice = '物件別契約情報管理を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '物件別契約情報管理を閉じる';
          actionNotice = '物件別契約情報管理から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'clearCondition':
          actionName = '検索クリア';
          actionNotice = '［検索クリア］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'addNew':
          actionName = '新規作成';
          actionNotice = '［新規作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'show':
          actionName = '選択';
          actionNotice = '契約先を選択';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'map':
          actionName = '地図表示';
          actionNotice = '［地図表示］メニューを押下';
          foregroundColor = '#888';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceContract':
      functionName = '契約データ管理';
      switch (actionHandle) {
        case 'enter':
          actionName = '契約データ管理画面を開く';
          actionNotice = '契約データ管理画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '契約データ管理画面を閉じる';
          actionNotice = '契約データ管理から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'clearCondition':
          actionName = '検索クリア';
          actionNotice = '［検索クリア］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'addNew':
          actionName = '新規作成';
          actionNotice = '［新規作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'import':
          actionName = 'インポート';
          actionNotice = '［インポート］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'export':
          actionName = 'エクスポート';
          actionNotice = '［エクスポート］ボタンを押下';
          foregroundColor = '#888';
          backgroundColor = '';
          break;
        case 'carryOver':
          actionName = '指定条件一括登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          break;
        case 'yearlyUpdate':
          actionName = '指定条件計画年月一括変更';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceContract-import':
      functionName = '契約データインポート';
      switch (actionHandle) {
        case 'enter':
          actionName = '契約データインポート画面を開く';
          actionNotice = '契約データインポート画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '契約データインポート画面を閉じる';
          actionNotice = '契約データインポートから他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          break;
        case 'registration-uploading':
          actionName = 'データを保存';
          actionNotice = 'データ保存中';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = '';
          break;
        case 'registration-success':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = 'データを保存';
          actionNotice = '［データを保存］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '#BDD7EE';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceContract-new':
      functionHandle = 'maintenanceContract-edit';
      functionName = '契約データ詳細（新規）';
      switch (actionHandle) {
        case 'enter':
          actionName = '契約データ詳細画面を開く';
          actionNotice = '契約データ詳細画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '契約データ詳細画面を閉じる';
          actionNotice = '契約データ詳細から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          statusResult = 'warning';
          break;
      }
      break;
    case 'maintenanceContract-edit':
      functionHandle = 'maintenanceContract-edit';
      functionName = '契約データ詳細（編集）';
      switch (actionHandle) {
        case 'enter':
          actionName = '契約データ詳細画面を開く';
          actionNotice = '契約データ管理画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '契約データ詳細画面を閉じる';
          actionNotice = '契約データ管理から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'complement':
          actionName = '指定条件の設定';
          actionNotice = '［指定条件の設定］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceContract-complement':
      functionName = '契約指定条件の編集';
      switch (actionHandle) {
        case 'enter':
          actionName = '契約指定条件の編集画面を開く';
          actionNotice = '契約指定条件の編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '契約指定条件の編集画面を閉じる';
          actionNotice = '契約指定条件の編集から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '指定条件の登録';
          actionNotice = '［指定条件の登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '指定条件の削除';
          actionNotice = '［指定条件の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '指定条件の削除';
          actionNotice = '［指定条件の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '指定条件の削除';
          actionNotice = '［指定条件の削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'readBuilding':
          actionName = '物件マスタから指定条件を読込む';
          actionNotice = '［分件マスタから指定条件を読込む］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'writeBuilding':
          actionName = '物件マスタの指定条件へ登録';
          actionNotice = '［物件マスタの指定条件へ登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          break;
        case 'writeBuilding-success':
          actionName = '物件マスタの指定条件へ登録';
          actionNotice = '［物件マスタの指定条件へ登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'writeBuilding-failure':
          actionName = '物件マスタの指定条件へ登録';
          actionNotice = '［物件マスタの指定条件へ登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'writeBuilding-validation':
          actionName = '物件マスタの指定条件へ登録';
          actionNotice = '［物件マスタの指定条件へ登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'deleteBuilding':
          actionName = '物件マスタの指定条件の削除';
          actionNotice = '［物件マスタの指定条件の削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'deleteBuilding-success':
          actionName = '物件マスタの指定条件の削除';
          actionNotice = '［物件マスタの指定条件の削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'deleteBuilding-failure':
          actionName = '物件マスタの指定条件の削除';
          actionNotice = '［物件マスタの指定条件の削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceContract-samework1':
      functionName = '同日実施の別契約作業';
      switch (actionHandle) {
        case 'release':
          actionName = '同日実施の別契約作業の全解除';
          actionNotice = '［全解除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '同日実施の別契約作業の登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '同日実施の別契約作業の登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceContract-samework2':
      functionName = '同日実施の別物件作業';
      switch (actionHandle) {
        case 'registration':
          actionName = '同日実施の別物件作業の登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '同日実施の別物件作業の登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceUser':
      functionName = 'ユーザーマスタ一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = 'ユーザーマスタ一覧画面を開く';
          actionNotice = 'ユーザーマスタ一覧画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'ユーザーマスタ一覧画面を閉じる';
          actionNotice = 'ユーザーマスタ一覧から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'clearCondition':
          actionName = '検索クリア';
          actionNotice = '［検索クリア］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'addNew':
          actionName = '新規作成';
          actionNotice = '［新規作成］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'export':
          actionName = 'エクスポート';
          actionNotice = '［エクスポート］ボタンを押下';
          foregroundColor = '#888';
          backgroundColor = '';
          break;
      }
      break;
    case 'maintenanceUser-edit':
      functionHandle = 'maintenanceUser-edit';
      functionName = 'ユーザーマスタ編集';
      switch (actionHandle) {
        case 'enter':
          actionName = 'ユーザーマスタ編集画面を開く';
          actionNotice = 'ユーザーマスタ編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'ユーザーマスタ編集画面を閉じる';
          actionNotice = 'ユーザーマスタ編集から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546A';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceUser-new':
      functionHandle = 'maintenanceUser-edit';
      functionName = 'ユーザーマスタ追加';
      switch (actionHandle) {
        case 'enter':
          actionName = 'ユーザーマスタ編集画面を開く';
          actionNotice = 'ユーザーマスタ編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'ユーザーマスタ編集画面を閉じる';
          actionNotice = 'ユーザーマスタ編集から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000CD';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'workdayPlanning':
      functionName = '稼働予定管理';
      switch (actionHandle) {
        case 'enter':
          actionName = '稼働予定管理画面を開く';
          actionNotice = '稼働予定管理画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '稼働予定管理画面を閉じる';
          actionNotice = '稼働予定管理から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'loadSchedule':
          actionName = 'データ読込';
          actionNotice = '［データ読込］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'loadSchedule-success':
          actionName = 'データ読込';
          actionNotice = '［データ読込］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'loadSchedule-failure':
          actionName = 'データ読込';
          actionNotice = '［データ読込］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'createNewSchedule':
          actionName = '要員追加・変更';
          actionNotice = '［要員追加・変更］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          break;
        case 'createNewSchedule-success':
          actionName = '要員追加・変更';
          actionNotice = '［要員追加・変更］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'createNewSchedule-failure':
          actionName = '要員追加・変更';
          actionNotice = '［要員追加・変更］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'persistSchedule':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          break;
        case 'persistSchedule-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'persistSchedule-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#44546a';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'deleteSchedule':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          break;
        case 'deleteSchedule-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'deleteSchedule-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'monthlyPlanning':
      functionName = '計画作成用データ確認';
      switch (actionHandle) {
        case 'enter':
          actionName = '計画作成用データ確認画面を開く';
          actionNotice = '計画作成用データ確認画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '計画作成用データ確認画面を閉じる';
          actionNotice = '計画作成用データ確認画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'clearCondition':
          actionName = '検索クリア';
          actionNotice = '［検索クリア］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'extract':
          actionName = 'データ抽出';
          actionNotice = '［データ抽出］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#ffccff';
          break;
        case 'extract-success':
          actionName = 'データ抽出';
          actionNotice = '［データ抽出］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#ffccff';
          statusResult = 'success';
          break;
        case 'extract-failure':
          actionName = 'データ抽出';
          actionNotice = '［データ抽出］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#ffccff';
          statusResult = 'failure';
          break;
        case 'subContract':
          actionName = '2日目以降の追加';
          actionNotice = '［2日目以降の追加］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'consignment':
          actionName = '委託一覧出力';
          actionNotice = '［委託一覧出力］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'consignment-success':
          actionName = '委託一覧出力';
          actionNotice = '［委託一覧出力］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = 'success';
          break;
        case 'consignment-failure':
          actionName = '委託一覧出力';
          actionNotice = '［委託一覧出力］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = 'failure';
          break;
        case 'sameWork1-success':
          actionName = '2日目以降同日作業１反映';
          actionNotice = '［2日目以降同日１］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#ffccff';
          statusResult = 'success';
          break;
        case 'sameWork1-failure':
          actionName = '2日目以降同日作業１反映';
          actionNotice = '［2日目以降同日１］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#ffccff';
          statusResult = 'failure';
          break;
      }
      break;
    case 'monthlyPlanning-subContract':
      functionName = '2日目以降の追加';
      switch (actionHandle) {
        case 'enter':
          actionName = '2日目以降の追加画面を開く';
          actionNotice = '2日目以降の追加画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '2日目以降の追加画面を閉じる';
          actionNotice = '2日目以降の追加画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'monthlyPlanning-secondDayGen':
      functionName = '２日目以降の一括追加';
      switch (actionHandle) {
        case 'enter':
          actionName = '２日目以降の一括追加を開く';
          actionNotice = '２日目以降の一括追加を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '２日目以降の一括追加を閉じる';
          actionNotice = '２日目以降の一括追加から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'registration-validation':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#0000cd';
          backgroundColor = '';
          statusResult = 'warning';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'monthlyConfirmation':
      functionName = '作業計画確認';
      switch (actionHandle) {
        case 'enter':
          actionName = '作業計画確認画面を開く';
          actionNotice = '作業計画確認画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '作業計画確認画面を閉じる';
          actionNotice = '作業計画確認画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'read':
          actionName = 'データ読込';
          actionNotice = '［データ読込］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'schedule':
          actionName = '作業計画処理';
          actionNotice = '［作業計画処理］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#FFCCFF';
          break;
        case 'schedule-success':
          actionName = '作業計画処理';
          actionNotice = '［作業計画処理］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#FFCCFF';
          statusResult = 'success';
          break;
        case 'schedule-failure':
          actionName = '作業計画処理';
          actionNotice = '［作業計画処理］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#FFCCFF';
          statusResult = 'failure';
          break;
        case 'swap':
          actionName = '要員入替';
          actionNotice = '［要員入替］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'output-excel1':
          actionName = '作業計画表（分析用）出力';
          actionNotice = '［Excel 作業計画表（分析用）］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'output-excel2':
          actionName = '作業計画表出力';
          actionNotice = '［Excel 作業計画表］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'display':
          actionName = '作業計画表表示';
          actionNotice = '［作業計画表表示］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'csvExport':
          actionName = 'CSVエクスポート';
          actionNotice = '［作業計画データCSV出力］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'iCalendar':
          actionName = 'iCalendarエクスポート';
          actionNotice = '［iCalendar］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'crewList':
          actionName = '担当者別作業予定表出力';
          actionNotice = '［担当者別作業予定表］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'contactList':
          actionName = '作業連絡表出力';
          actionNotice = '［作業連絡表出力］メニューを選択';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'statusRegistration':
          actionName = '確定状況の登録';
          actionNotice = '［確定状況の登録］ボタンを押下';
          foregroundColor = '#1E90FF';
          backgroundColor = '';
          break;
        case 'statusRegistration-success':
          actionName = '確定状況の登録';
          actionNotice = '［確定状況の登録］ボタンを押下';
          foregroundColor = '#1E90FF';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'statusRegistration-failure':
          actionName = '確定状況の登録';
          actionNotice = '［確定状況の登録］ボタンを押下';
          foregroundColor = '#1E90FF';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'allConfirmed':
          actionName = '確定状況の登録';
          actionNotice = '［全て確定］ボタンを押下';
          foregroundColor = '#1E90FF';
          backgroundColor = '';
          break;
        case 'allReset':
          actionName = '確定状況の登録';
          actionNotice = '［全て再割当］ボタンを押下';
          foregroundColor = '#1E90FF';
          backgroundColor = '';
          break;
        case 'showUnAssigned':
          actionName = '未割当データ表示';
          actionNotice = '［未割当データ表示］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'showEdit':
          actionName = '編集';
          actionNotice = '［編集］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'showActual':
          actionName = '実績';
          actionNotice = '［実績］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'monthlyConfirmation-unassigned':
      functionName = '作業計画確認（未割当）';
      switch (actionHandle) {
        case 'enter':
          actionName = '作業計画確認（未割当）画面を開く';
          actionNotice = '作業計画確認（未割当）画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '作業計画確認（未割当）画面を閉じる';
          actionNotice = '作業計画確認（未割当）画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'showUnAssigned':
          actionName = '未割当データ非表示';
          actionNotice = '［非表示］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'showAdd':
          actionName = '未割当データ追加';
          actionNotice = '［追加］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'monthlyConfirmation-edit':
      functionHandle = 'monthlyConfirmation-edit';
      functionName = '計画データの編集';
      switch (actionHandle) {
        case 'enter':
          actionName = '計画データの編集画面を開く';
          actionNotice = '計画データの編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '計画データの編集画面を閉じる';
          actionNotice = '計画データの編集画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '割当登録';
          actionNotice = '［割当登録］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '割当登録';
          actionNotice = '［割当登録］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '割当登録';
          actionNotice = '［割当登録］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'crewAdd':
          actionName = '要員追加';
          actionNotice = '［要員追加］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'monthlyConfirmation-new':
      functionHandle = 'monthlyConfirmation-edit';
      functionName = '未割当データの追加登録';
      switch (actionHandle) {
        case 'enter':
          actionName = '計画データの編集画面を開く';
          actionNotice = '計画データの編集画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '計画データの編集画面を閉じる';
          actionNotice = '計画データの編集画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '割当登録';
          actionNotice = '［割当登録］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '割当登録';
          actionNotice = '［割当登録］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '割当登録';
          actionNotice = '［割当登録］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'crewAdd':
          actionName = '要員追加';
          actionNotice = '［要員追加］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'monthlyConfirmation-actual':
      functionName = '作業実績入力';
      switch (actionHandle) {
        case 'enter':
          actionName = '作業実績入力画面を開く';
          actionNotice = '作業実績入力画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '作業実績入力画面を閉じる';
          actionNotice = '作業実績入力画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#8080800';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '登録';
          actionNotice = '［登録］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'delete-failure':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'monthlyConfirmation-calendar':
      functionName = '作業予定データのエクスポート';
      switch (actionHandle) {
        case 'icalendar-success':
          actionName = 'データ出力';
          actionNotice = '［出力］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'icalendar-failure':
          actionName = 'データ出力';
          actionNotice = '［出力］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          statusResult = 'failure';
          break;
        case 'cancel':
          actionName = 'キャンセル';
          actionNotice = '［キャンセル］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'icalendar-linkCopy':
          actionName = 'クリップボードにコピー';
          actionNotice = '［リンクコピー］ボタンを押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
        case 'icalendar-link':
          actionName = 'リンク';
          actionNotice = '［リンク］を押下';
          foregroundColor = '#808080';
          backgroundColor = '';
          break;
      }
      break;
    case 'applicationLogging':
      functionName = 'システムログ一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = '作業実績入力画面を開く';
          actionNotice = '作業実績入力画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '作業実績入力画面を閉じる';
          actionNotice = '作業実績入力画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'sessionMonitor':
      functionName = 'ログイン中ユーザー一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = 'ログイン中ユーザー一覧画面を開く';
          actionNotice = 'ログイン中ユーザー一覧画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'ログイン中ユーザー一覧画面を閉じる';
          actionNotice = 'ログイン中ユーザー一覧画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'welcome':
      functionName = 'ホーム画面';
      switch (actionHandle) {
        case 'enter':
          actionName = 'ホーム画面を開く';
          actionNotice = 'ホーム画面を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'ホーム画面を閉じる';
          actionNotice = 'ホーム画面から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'crewEdit':
      functionName = '要員追加';
      switch (actionHandle) {
        case 'enter':
          actionName = '要員追加を開く';
          actionNotice = '要員追加を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '要員追加を閉じる';
          actionNotice = '要員追加から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'append':
          actionName = '要員追加';
          actionNotice = '［要員追加］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'remove':
          actionName = '要員削除';
          actionNotice = '［要員削除］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'save':
          actionName = '要員登録';
          actionNotice = '［要員登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
      }
      break;
    case 'crewChange':
      functionName = '要員入替';
      switch (actionHandle) {
        case 'enter':
          actionName = '要員入替を開く';
          actionNotice = '要員入替を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = '要員入替を閉じる';
          actionNotice = '要員入替から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration':
          actionName = '変更登録';
          actionNotice = '［変更登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'registration-success':
          actionName = '変更登録';
          actionNotice = '［変更登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'success';
          break;
        case 'registration-failure':
          actionName = '変更登録';
          actionNotice = '［変更登録］ボタンを押下';
          foregroundColor = '#000';
          backgroundColor = '';
          statusResult = 'failure';
          break;
      }
      break;
    case 'maintenanceApplicationLogging':
      functionName = 'システムログ一覧';
      switch (actionHandle) {
        case 'enter':
          actionName = 'システムログ一覧を開く';
          actionNotice = 'システムログ一覧を表示';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'exit':
          actionName = 'システムログ一覧を閉じる';
          actionNotice = 'システムログ一覧から他の画面へ遷移';
          foregroundColor = '#000';
          backgroundColor = '';
          break;
        case 'delete':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#FFCCFF';
          break;
        case 'delete-success':
          actionName = '削除';
          actionNotice = '［削除］ボタンを押下';
          foregroundColor = '#800000';
          backgroundColor = '#FFCCFF';
          statusResult = 'success';
          break;
      }
      break;
  }

  const msg = {
    messageType: 'applicationLogging',
    functionHandle,
    actionHandle,
    functionName,
    actionName,
    actionNotice,
    message,
    foregroundColor,
    backgroundColor,
    statusResult: statusResult ?? '',
    targetYearmonth: targetYearmonth ?? '',
    sectionCode: sectionCode ?? '',
    targetId: targetId ?? '',
    tenantId: window.tenantId ?? '',
    userId: window.userId ?? '',
  } as ApplicationLoggingMessage;

  if (
    msg.actionHandle != 'clearCondition' &&
    msg.actionHandle != 'enter' &&
    msg.actionHandle != 'exit' &&
    msg.actionHandle !=
      'loaded' /* && !msg.actionHandle.endsWith('-success') && !msg.actionHandle.endsWith('-failure') && !msg.actionHandle.endsWith('-validation') */
  ) {
    await axios.put('/api/applicationLogging/v2', msg);
  }
  publish<ApplicationLoggingMessage>('applicationLogging', msg);
};

export default ApplicationLog;
