import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useClickOutside, useDisclosure, useFullscreen } from '@mantine/hooks';
import {
  IconAdjustments,
  IconBug,
  IconCalendarStats,
  IconDatabase,
  IconListCheck,
  IconNotes,
  IconPresentationAnalytics,
} from '@tabler/icons-react';

import { cn } from 'mxcn';

import { Footer } from '~/layouts/Footer';
import { Header } from '~/layouts/Header';
import { Navbar } from '~/layouts/Navbar';

import PosterIcon from '~/features/poster/common/PosterIcon';

import WebVitals from '~/features/WebVitals';

import { LinksGroup } from '~/components/ui/aspi/NavbarLinksGroup/NavbarLinksGroup';

import classes from './Default.module.css';

export default () => {
  const [menuOpened, menuHandlers] = useDisclosure(false);

  // const roles = new Set(window.roles);

  // const rSysAdmin = roles.has('r_sysadmin');
  // const rAdmin = roles.has('r_admin') && !rSysAdmin;
  // const rUser = roles.has('r_user') && !rAdmin && !rSysAdmin;

  // const showVehicle = window.settings.tenant.showVehicle;

  // const [dropdown, setDropdown] = useState<any | null>(null);
  // const [control, setControl] = useState<any | null>(null);
  // useClickOutside(() => menuHandlers.close(), ['mouseup', 'touchend'], [control, dropdown]);

  const menu = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            window.permissions.includes('p_contract')
              ? {
                  label: '契約情報管理',
                  icon: IconNotes,
                  initiallyOpened: false,
                  links: [
                    { label: '契約データ', link: '/contract' },
                    window.settings.tenant.yearlyContractEnabled === true
                      ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                      : null,
                  ].filter(f => f != null),
                }
              : null,
            window.permissions.includes('p_planning')
              ? {
                  label: '作業計画管理',
                  icon: IconCalendarStats,
                  links: [
                    window.permissions.includes('p_workday_planning')
                      ? { label: '稼働予定', link: '/planning/crew' }
                      : null,
                    window.permissions.includes('p_monthly_planning')
                      ? { label: '計画作成用データ確認', link: '/planning/work' }
                      : null,
                    window.permissions.includes('p_monthly_confirmation')
                      ? { label: '作業計画確認', link: '/planning/confirmation' }
                      : null,
                    window.permissions.includes('p_kng_sample')
                      ? { label: 'メンテナンス工程表', link: '/sample' }
                      : null,
                  ].filter(f => f != null),
                }
              : null,
            window.settings.tenant.poster && window.permissions.includes('p_poster')
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview', disabled: false },
                    { label: 'チラシフォーム管理', link: '/poster/template', disabled: false },
                    { label: 'お知らせ状況管理', link: '/poster/project', disabled: false },
                  ],
                }
              : null,
            window.settings.tenant.project != null && window.permissions.includes('p_project')
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            window.permissions.includes('p_analytics')
              ? { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true }
              : null,
            window.permissions.includes('p_master')
              ? {
                  label: 'マスタ管理',
                  icon: IconDatabase,
                  links: [
                    window.permissions.includes('p_building')
                      ? { label: '物件マスタ', link: '/maintenance/building', disabled: false }
                      : null,
                    window.permissions.includes('p_customer')
                      ? { label: '取引先マスタ', link: '/maintenance/customer', disabled: false }
                      : null,
                    window.settings.tenant.showVehicle && window.permissions.includes('p_vehicle')
                      ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false }
                      : null,
                    window.permissions.includes('p_user')
                      ? { label: 'ユーザマスタ', link: '/maintenance/user', disabled: false }
                      : null,
                    window.permissions.includes('p_section')
                      ? { label: '部門マスタ', link: '/maintenance/section', disabled: false }
                      : null,
                    window.permissions.includes('p_operation')
                      ? { label: '作業マスタ', link: '/maintenance/operation', disabled: false }
                      : null,
                    window.permissions.includes('p_operation')
                      ? { label: '作業分類マスタ', link: '/maintenance/operationCategory', disabled: false }
                      : null,
                    window.permissions.includes('p_qualification')
                      ? { label: '資格・技能マスタ', link: '/maintenance/qualification', disabled: false }
                      : null,
                  ].filter(f => f != null),
                }
              : null,

            window.permissions.includes('p_system')
              ? {
                  label: 'システム管理',
                  icon: IconAdjustments,
                  links: [
                    window.permissions.includes('p_export')
                      ? { label: 'エクスポート設定', link: '/export', disabled: false }
                      : null,
                    { label: 'システム設定', link: '/config', disabled: false },
                    { label: 'ログ管理', link: '/applicationLogging', disabled: false },
                    { label: 'ログイン中ユーザー一覧', link: '/sessionMonitor', disabled: false },
                    { label: 'ライセンス情報', link: '/license', disabled: false },
                  ].filter(f => f != null),
                }
              : null,
            window.permissions.includes('p_developer')
              ? {
                  label: '開発者',
                  icon: IconBug,
                  links: [{ label: 'テスト', link: '/test/timepicker', disabled: false }],
                }
              : null,
          ].filter(f => f != null)
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const links = useMemo(
    () => (
      <>
        {menu.map(item => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <LinksGroup
            {...item}
            key={item?.label ?? ''}
            onClick={e => {
              menuHandlers.close();
              //setTimeout(menuHandlers.close, 0);
            }}
          />
        ))}
      </>
    ),
    [menu]
  );

  return (
    <div className={classes.main}>
      <Header handler={[menuOpened, menuHandlers]} />
      <Navbar links={links} handler={[menuOpened, menuHandlers]} />
      <main className="p-2" style={{ height: 'calc( 100vh - 52px' }}>
        <Outlet />
      </main>
      <Footer />
      <WebVitals />
    </div>
  );
};
