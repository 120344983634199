import React from 'react';

import { notifications } from '@mantine/notifications';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

import { publish, subscribe } from '~/utils/event';

import { MessageWrapper } from '~/workers/session/message';

const { watchdogWorker } = window;

interface IMessage {
  message: string;
}

declare global {
  interface Window {
    watchdogWorker: Worker;
  }
}

export const registerWatchdog = async () => {
  const watchdog = new Worker(new URL('./worker.ts', import.meta.url), {
    /* normal Worker options */
  });
  window.watchdogWorker = watchdog;

  subscribe('watchdog', (evt: Event) => {
    const msg = (evt as CustomEvent).detail as IWatchdogMessage;
    // console.log(msg);
    watchdog.postMessage(msg);
  });

  watchdog.onmessage = (event: MessageEvent) => {
    // console.log(event.data);
    const data = event.data as IMessage;
    if (event.type === 'kickOut') {
      publish('kickOut', event.data.message as MessageWrapper<any>);
      notifications.show({
        id: 'kickOut',
        autoClose: false,
        title: '強制ログアウト',
        message: event.data.message.message ?? 'システム管理者の操作により強制ログアウトしました。',
        icon: <IconX />,
        color: 'red',
      });
    } else if (event.type === 'sendMessage') {
      publish('sendMessage', event.data.message as MessageWrapper<any>);
      if (event.data.message.message != null && event.data.message.message.length > 0) {
        notifications.show({
          id: 'sendMessage',
          autoClose: false,
          title: 'メッセージ',
          message: event.data.message.message ?? '',
          icon: <IconInfoCircle />,
          color: 'blue',
        });
      }
    } else {
      publish('receive', event.data as MessageWrapper<any>);
    }
  };
};

interface IWatchdogMessage {
  instanceId: string;
  clientId: string;
  fingerprint: string;
  userId: string | undefined | null;
  userCode: string | undefined | null;
  userName: string | undefined | null;
  tenantId: string | undefined | null;
  functionHandle: string;
  functionName: string;
  targetYearmonth: string | undefined;
  sectionCode: string | undefined;
  targetId: string | undefined;
}

export const watchdog = (
  functionHandle: string,
  functionName: string,
  targetYearmonth: string = '',
  sectionCode: string = '',
  targetId: string = ''
) => {
  const msg = {
    instanceId: window.instanceId,
    clientId: localStorage.getItem('client-id'),
    fingerprint: localStorage.getItem('fingerprint'),
    userId: window.userId,
    userCode: window.userCode,
    userName: window.userName,
    tenantId: window.tenantId,
    functionHandle,
    functionName,
    targetYearmonth: targetYearmonth ?? '',
    sectionCode: sectionCode ?? '',
    targetId: targetId ?? '',
  } as IWatchdogMessage;
  publish('watchdog', msg);
};
