import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutBlank from '~/layouts/Blank';
import LayoutDefault from '~/layouts/Default';

import TimePicker2TestView from '~/features/aspi/Complement/components/TimePicker2/TimePicker2TestView';

import ApplicationLoggingView from '~/views/aspi/ApplicationLoggingView';
import BuildingMaintenanceView from '~/views/aspi/BuildingMaintenanceView';
import ContractMaintenanceView from '~/views/aspi/ContractMaintenanceView';
import CrewCategoryMaintenanceView from '~/views/aspi/CrewCategoryMaintenanceView';
import CustomerMaintenanceView from '~/views/aspi/CustomerMaintenanceView';
import ExportSettingsView from '~/views/aspi/ExportSettingsView';
import KNGSampleView from '~/views/aspi/KNGSampleView';
import LicenseView from '~/views/aspi/License';
import MonthlyConfirmationView from '~/views/aspi/MonthlyConfirmationView';
import MonthlyPlanningView from '~/views/aspi/MonthlyPlanningView';
import OperationCategoryMaintenanceView from '~/views/aspi/OperationCategoryMaintenanceView';
import OperationMaintenanceView from '~/views/aspi/OperationMaintenanceView';
import PermissionMaintenanceView from '~/views/aspi/PermissionMaintenanceView';
import ProjectView from '~/views/aspi/ProjectView';
import QualificationMaintenanceView from '~/views/aspi/QualificationMaintenanceView';
import RoleMaintenanceView from '~/views/aspi/RoleMaintenanceView';
import SectionMaintenanceView from '~/views/aspi/SectionMaintenanceView';
import SessionMonitorView from '~/views/aspi/SessionMonitorView';
import SettingsView from '~/views/aspi/SettingsView';
import UserMaintenanceView from '~/views/aspi/UserMaintenanceView';
import VehicleMaintenanceView from '~/views/aspi/VehicleMaintenanceView';
import WelcomeView from '~/views/aspi/Welcome';
import WorkScheduleView from '~/views/aspi/WorkPlanningView';
import YearlyContractView from '~/views/aspi/YearlyContractView';
import Blank from '~/views/Blank';
import PosterPreviewView from '~/views/poster/PosterPreviewView';
import PosterProjectView from '~/views/poster/PosterProjectView';
import PosterTemplateView from '~/views/poster/PosterTemplateView';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<LayoutDefault />}>
        <Route path="/" element={<WelcomeView />} />
        <Route path="contract" element={<ContractMaintenanceView />} />
        <Route path="yearlyContract" element={<YearlyContractView />} />
        <Route path="planning/crew" element={<WorkScheduleView />} />
        <Route path="planning/work" element={<MonthlyPlanningView />} />
        <Route path="planning/confirmation" element={<MonthlyConfirmationView />} />
        <Route path="maintenance/building" element={<BuildingMaintenanceView />} />
        <Route path="maintenance/customer" element={<CustomerMaintenanceView />} />
        <Route path="maintenance/user" element={<UserMaintenanceView />} />
        <Route path="maintenance/section" element={<SectionMaintenanceView />} />
        <Route path="maintenance/operation" element={<OperationMaintenanceView />} />
        <Route path="maintenance/operationCategory" element={<OperationCategoryMaintenanceView />} />
        <Route path="maintenance/qualification" element={<QualificationMaintenanceView />} />
        <Route path="maintenance/crewCategory" element={<CrewCategoryMaintenanceView />} />
        <Route path="maintenance/role" element={<RoleMaintenanceView />} />
        <Route path="maintenance/permission" element={<PermissionMaintenanceView />} />
        <Route path="maintenance/vehicle" element={<VehicleMaintenanceView />} />
        <Route path="applicationLogging" element={<ApplicationLoggingView />} />
        <Route path="sessionMonitor" element={<SessionMonitorView />} />
        <Route path="license" element={<LicenseView />} />
        <Route path="blank" element={<Blank />} />

        {/*<Route path="test/timepicker" element={<TimePicker2TestView />} />*/}

        <Route path="poster/template" element={<PosterTemplateView />} />
        <Route path="poster/project" element={<PosterProjectView />} />
        <Route path="poster/preview" element={<PosterPreviewView />} />

        <Route path="config" element={<SettingsView />} />

        <Route path="project" element={<ProjectView />} />
        <Route path="export" element={<ExportSettingsView />} />

        <Route path="sample" element={<KNGSampleView />} />
      </Route>
      crew
      <Route element={<LayoutBlank />}>
        <Route path="blank" element={<Blank />} />
      </Route>
      {/* <Route element={<LayoutBlank />}> */}
      {/*  <Route path="*" element={<Error404 />} /> */}
      {/* </Route> */}
    </Routes>
  </BrowserRouter>
);

export default Router;
