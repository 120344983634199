'use client';

import { Suspense, lazy } from 'react';

import { Loader } from '@mantine/core';

import { uuidv4Math } from '~/utils/uuidv4Math';

const ContractMaintenance = lazy(() => import('~/features/aspi/Contract'));

const ContractMaintenanceView = () => {
  // const uuid = uuidv4Math();

  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
        <ContractMaintenance /*key={uuid} */ /*tenantId={window.tenantId!}*/ />
      </div>
    </Suspense>
  );
};

export default ContractMaintenanceView;
