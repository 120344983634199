import { PropsWithChildren } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { NavigationProgress } from '@mantine/nprogress';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { Provider, createStore } from 'jotai';
import { useHydrateAtoms } from 'jotai/react/utils';
import { queryClientAtom } from 'jotai-tanstack-query';

import { axiosInstance } from '~/axios';
import { persister, queryClient } from '~/query';
import { theme } from '~/theme';

import AxiosProvider from './AxiosProvider';

const rootStore = createStore();

const HydrateAtoms = (props: PropsWithChildren) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);

  return props.children;
};

const tokenProvider = () => localStorage.getItem('token');

export const ApplicationRoot = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <Provider store={rootStore}>
        <HydrateAtoms>
          <AxiosProvider axios={axiosInstance} tokenProvider={tokenProvider}>
            <I18nProvider i18n={i18n}>
              <MantineProvider defaultColorScheme="light" theme={theme}>
                <NavigationProgress />
                <Notifications limit={5} containerWidth={500} notificationMaxHeight={100} transitionDuration={0} />
                <ModalsProvider>{children}</ModalsProvider>
              </MantineProvider>
            </I18nProvider>
          </AxiosProvider>
        </HydrateAtoms>
      </Provider>
    </PersistQueryClientProvider>
  );
};
